import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '*',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior() {
  //   return {
  //     x: 0,
  //     y: 0,
  //   };
  // }
});

export default router;
