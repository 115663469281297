<template>
  <div id="app" :class="{ green: isGreen, blue: !isGreen }">
    <!-- <div @click="isGreen = !isGreen" class="toggle">Farbe ändern</div> -->

    <Navigation @changeColor="randomColor()" />
    <!-- <router-view /> -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";

export default {
  name: "App",
  components: {
    Navigation,
  },
  data() {
    return {
      isGreen: false,
    };
  },
  mounted() {
    this.randomColor();
  },
  methods: {
    randomColor() {
      const chance = Math.random();
      // console.log(chance);
      if (chance > 0.8) {
        this.isGreen = true;
      } else {
        this.isGreen = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "node_modules/bootstrap-scss/bootstrap-grid.scss";

.toggle {
  background-color: white;
  padding: 1rem;
  position: absolute;
  right: 0;
  z-index: 10;
}

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding: 0px;
}

#app {
  font-family: HelveticaNeueLTPro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  background-color: $blue;
  &.green {
    background-color: $green;
    h2,
    h1,
    p,
    a {
      color: black !important;
    }
    .onblue {
      display: none;
    }
    ::selection {
      color: $green;
      background-color: $black;
    }
  }
  &.blue {
    .ongreen {
      display: none;
    }
    ::selection {
      color: $blue;
      background-color: $white;
    }
  }
}
h1,
p,
a {
  font-size: 28px;
  line-height: 1.2;
  font-weight: normal;

  @media (min-width: $xs) {
    font-size: 32px;
  }
}
</style>
