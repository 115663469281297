<template>
  <nav id="nav">
    <router-link to="/">
      <img
        src="@/assets/logos/thepool-logo.svg"
        height="20"
        alt="The Pool"
        class="onblue"
        @click="$emit('changeColor')"
      />
      <img
        src="@/assets/logos/thepool-logo-black.svg"
        height="20"
        alt="The Pool"
        class="ongreen"
        @click="$emit('changeColor')"
      />
    </router-link>
    <router-link to="/contact">Contact</router-link>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
};
</script>

<style lang="scss">
#nav {
  position: absolute;
  padding: 2rem 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 100 !important;
  a {
    display: block;
    color: $white;

    font-size: 1rem;
    font-weight: bold;

    text-transform: uppercase;
    text-decoration: none;

    &:not(:first-child) {
      margin-left: 30px;
    }

    &.router-link-exact-active {
      color: $white;
    }
  }
}
</style>
